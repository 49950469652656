// third-party
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['adminDashboard'] = {
    error: null,
    fetching: false,
    restaurantCount: 0,
    guestOnlyCount: 0,
    serverCount: 0,
    recentServerList: [],
    restaurantList: [],
    serverList: [],
    guestList: [],
    totalSale: 0,
    todaySale: 0
};

const dashboard = createSlice({
    name: 'adminDashboard',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, { payload }) {
            state.error = payload;
            state.fetching = false;
        },
        getDashboardPending(state) {
            state.fetching = true;
        },
        initialize() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboard.fulfilled, (state: DefaultRootStateProps['adminDashboard'], { payload, type }) => ({
                ...state,
                fetching: false,
                error: null,
                ...payload
            }))

            .addMatcher(isAnyOf(getDashboard.pending), (state) => {
                state.fetching = true;
            })
            .addMatcher(isAnyOf(getDashboard.rejected), (state: DefaultRootStateProps['adminDashboard'], { payload }: any) => {
                state.error = payload;
                state.fetching = false;
            });
    }
});

// ----------------------------------------------------------------------

export const getDashboard = createAsyncThunk('get-adminDashboard', async (params: any, { rejectWithValue }) => {
    let returner = null;
    try {
        const { data } = await axios.get('/admin/dashboard');
        returner = data;
    } catch (error) {
        rejectWithValue(error);
    }
    return returner;
});

export const makeitEmpty = () => () => {
    dispatch(dashboard.actions.initialize());
};

export const { initialize } = dashboard.actions;
// Reducer
export default dashboard.reducer;
