import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// page routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const Guests = Loadable(lazy(() => import('views/guests')));
const Servers = Loadable(lazy(() => import('views/servers')));
const Restaurants = Loadable(lazy(() => import('views/restaurants')));
const Settings = Loadable(lazy(() => import('views/settings')));
const MerchantStore = Loadable(lazy(() => import('views/merchStore')));
const MerchantTheme = Loadable(lazy(() => import('views/merchStore/merchTheme')));
const MerchantAward = Loadable(lazy(() => import('views/merchStore/merchAward')));
const UserView = Loadable(lazy(() => import('views/userDetails')));
const Transaction = Loadable(lazy(() => import('views/transaction')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/transaction',
            element: <Transaction />
        },
        {
            path: '/transaction/user/:id',
            element: <UserView />
        },
        {
            path: '/restaurants',
            element: <Restaurants />
        },
        {
            path: '/guests',
            element: <Guests />
        },
        {
            path: '/servers',
            element: <Servers />
        },
        {
            path: '/guests/:id',
            element: <UserView />
        },
        {
            path: '/servers/:id',
            element: <UserView />
        },
        {
            path: '/settings',
            element: <Settings />
        },
        {
            path: '/merchstore',
            element: <MerchantStore />
        },
        {
            path: '/merchstore/theme',
            element: <MerchantTheme />
        },
        {
            path: '/merchstore/theme/:id',
            element: <MerchantTheme />
        },
        {
            path: '/merchstore/award',
            element: <MerchantAward />
        },
        {
            path: '/merchstore/award/:id',
            element: <MerchantAward />
        }
    ]
};

export default MainRoutes;
