import ReactGA from 'react-ga4';

// Set default American Currency Formatted price
const dollarUSLocale = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

// Greeding With respect to current local time
export const greedingWRTTime = (): string => {
    const commonString = 'Good ';
    const nowDate = new Date();
    const hrs = nowDate.getHours();
    if (hrs >= 12 && hrs <= 17) {
        return `${commonString}Afternoon`;
    }
    if (hrs >= 17 && hrs <= 24) {
        return `${commonString}Evening`;
    }
    return `${commonString}Morning`;
};

export const errorStatusChecker = (resFromAPI: any) => {};

// No operation
export const noop = (): void => {};

// Analytic Hook
export const useAnalyticsEventTracker = (category = 'test category') => {
    const eventTracker = (action = 'test action', label = 'test label') => {
        ReactGA.event({ category, action, label });
    };
    return eventTracker;
};

// Get American time formate in UTC timezone
// export const americanTime = (dateToConvert: string | Date, withTime: boolean = false) => {
//     const date = dateToConvert ? new Date(dateToConvert) : '';
//     const dateToString = date?.toLocaleString('en-US', {
//         year: 'numeric',
//         month: 'numeric',
//         day: 'numeric',
//         ...(withTime ? { hour: 'numeric', minute: 'numeric', timeZone: 'UTC' } : {})
//     });
//     return `${dateToString} ${withTime ? 'UTC' : ''}`;
// };

// Get American time formate in Local timezone
export const americanTime = (dateToConvert: string | Date, withTime: boolean = false) => {
    const date = new Date(dateToConvert || '');
    let dateToString: string = '';
    if (withTime) {
        const dateFormat = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });
        const { timeZone } = dateFormat.resolvedOptions();
        dateToString = new Intl.DateTimeFormat('en-US', {
            dateStyle: 'short',
            timeStyle: 'long',
            timeZone
        }).format(date);
    } else {
        dateToString = new Intl.DateTimeFormat('en-US', {
            dateStyle: 'short'
        }).format(date);
    }
    return dateToString;
};

// Get American Currency Formatted price
export const americanCurrency = (price: string | number) =>
    `${dollarUSLocale.format(typeof price === 'string' ? parseFloat(price) : price)}`;

// Force User not to input more then 2 decimal
export const priceAdjustmentInput = (
    inputObj: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    handleChange: (e: React.ChangeEvent<any>) => void
) => {
    const tempObj = { ...inputObj };
    let tNum = tempObj.target.value;
    if (tNum.includes('.')) {
        // Check if it is decimal
        const dd = tNum.split('.'); // splitting the decimal part
        if (dd[1].length > 2) {
            const ddd = Array.from(dd[1]);
            ddd.splice(2, 1); // remove more then 2 length from string
            tNum = `${dd[0]}.${ddd.join('')}`; // rejoin the whole string
        }
    }
    tempObj.target.value = tNum || '0.5'; // if value is empty set default 0.5
    handleChange(tempObj);
};

// Check Image size
export const checkIfFilesAreTooBig = (sizeToCheck: number, file?: File): boolean => {
    let valid = false;
    if (file) {
        const size = file.size / 1024;
        valid = size <= sizeToCheck;
    }
    return valid;
};

// Check Dimensions for Image
export const checkIfFilesDimensionsAreCorrect = (width: number, file?: File): Promise<boolean> =>
    new Promise((resolve) => {
        if (file) {
            const img = new Image();
            const URL = window.URL || window.webkitURL;
            const objectUrl = URL.createObjectURL(file);
            img.onload = () => {
                const aspectRatio = img.width / img.height;
                const cond1 = img.width <= width;
                const cond2 = aspectRatio === 1;
                URL.revokeObjectURL(objectUrl);
                resolve(cond1 && cond2);
            };
            img.src = objectUrl;
        } else {
            resolve(false);
        }
    });

// setting Expiry settings consts
export const settingsKeys = {
    pinExp: 'pin_expiry_in_days',
    reviewExp: 'review_expiry_in_days'
};

// Method for sorting by date to the latest
export const sortByLatest = (a: { createdAt: Date }, b: { createdAt: Date }) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
