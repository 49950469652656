import axios from 'axios';
import { dispatch } from 'store';
import { LOGOUT } from 'store/actions';
import { openSnackbar } from 'store/slices/snackbar';

const manageErrorConnection = (err: any) => {
    console.log('Error', err.response);
    const { data, status } = err.response || {};
    if (status === 401) {
        // 401 should be handled as logout
        dispatch({ type: LOGOUT });
    } else if (status === 404) {
        // 404 should not show generic message | NotFoundExeption
        dispatch(
            openSnackbar({
                open: true,
                message: 'Not found',
                variant: 'alert',
                transition: 'SlideLeft',
                alert: {
                    color: 'error'
                }
            })
        );
    } else if (status === 400) {
        // 400 should be handled on slice level | ValidateMessage
        console.log('ValidateMessageError', data?.validateMessage);
    }
    return Promise.reject(err);
};

const axiosServices = axios.create({
    timeout: 60000,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { accept: 'application/json', 'Content-Type': 'application/json' }
});

axiosServices.interceptors.response.use((response) => response, manageErrorConnection);

export default axiosServices;
