// third-party
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['transaction'] = {
    error: null,
    transactions: [],
    fetching: false,
    count: 0,
    limit: 0,
    offset: 0,
    totalTransactionAmount: 0,
    totalPinTransactionsAmount: 0,
    totalThemeTransactionsAmount: 0,
    totalPinTransactions: 0,
    totalThemeTransactions: 0,
    yearsOfTransactions: []
};

// ----------------------------------------------------------------------

export const getTransactionList = createAsyncThunk(
    'get-transactionsList',
    async (
        {
            limit = 25,
            offset = 0,
            search = '',
            sortBy = 'id',
            sortOrder = 'ASC',
            year
        }: {
            limit?: number;
            year?: string;
            offset?: number;
            search?: string;
            sortBy?: string;
            sortOrder?: 'ASC' | 'DESC';
        },
        { rejectWithValue }
    ) => {
        let returner = null;
        try {
            const { data } = await axios.get(
                `/payments/transactions?limit=${limit}${year ? `&year=${year}` : ''}&offset=${offset}${search ? `&search=${search}` : ''}${
                    sortBy ? `&sortBy=${sortBy}` : ''
                }${sortOrder ? `&sortOrder=${sortOrder}` : ''}`
            );
            returner = data;
        } catch (error) {
            rejectWithValue(error);
        }
        return returner;
    }
);
export const getTransactionStates = createAsyncThunk('get-TransactionStates', async (payload: { year?: string }, { rejectWithValue }) => {
    let returner = null;
    try {
        const { data } = await axios.get(`payments/transactions/stats${payload.year ? `?year=${payload.year}` : ''}`);
        returner = data;
    } catch (error) {
        rejectWithValue(error);
    }
    return returner;
});

const slice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
            state.fetching = false;
        },
        getTransactionsPending(state) {
            state.fetching = true;
            state.error = null;
        },
        initialize() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTransactionList.fulfilled, (state: DefaultRootStateProps['transaction'], { payload }) => {
                const { data, pagination } = payload;
                return {
                    ...state,
                    fetching: false,
                    error: null,
                    transactions: data,
                    ...pagination
                };
            })
            .addCase(getTransactionStates.fulfilled, (state: DefaultRootStateProps['transaction'], { payload }) => ({
                ...state,
                fetching: false,
                error: null,
                ...payload
            }))

            .addMatcher(isAnyOf(getTransactionList.pending, getTransactionStates.pending), (state) => {
                state.fetching = true;
                state.error = null;
            })
            .addMatcher(
                isAnyOf(getTransactionList.rejected, getTransactionStates.rejected),
                (state: DefaultRootStateProps['transaction'], { payload }: any) => {
                    state.error = payload;
                    state.fetching = false;
                }
            );
    }
});

export const makeItEmpty = () => () => {
    dispatch(slice.actions.initialize());
};

// Reducer
export default slice.reducer;
