// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHome, IconHelp, IconUser, IconUserExclamation, IconBuildingStore, IconShoppingCart, IconTool, IconCoin } from '@tabler/icons';

// constant
const icons = {
    IconHome,
    IconHelp,
    IconUser,
    IconBuildingStore,
    IconShoppingCart,
    IconTool,
    IconUserExclamation,
    IconCoin
};

// ==============================|| SS PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'ss-1',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        {
            id: 'restaurants',
            title: <FormattedMessage id="Restaurants" />,
            type: 'item',
            url: '/restaurants',
            icon: icons.IconBuildingStore,
            breadcrumbs: false
        },

        {
            id: 'servers',
            title: <FormattedMessage id="Servers" />,
            type: 'item',
            url: '/servers',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'guests',
            title: <FormattedMessage id="Guests" />,
            type: 'item',
            url: '/guests',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'settings',
            title: <FormattedMessage id="Settings" />,
            type: 'item',
            url: '/settings',
            icon: icons.IconTool,
            breadcrumbs: false
        },
        {
            id: 'merchstore',
            title: <FormattedMessage id="Merch Store" />,
            type: 'item',
            url: '/merchstore',
            icon: icons.IconShoppingCart,
            breadcrumbs: false
        },
        {
            id: 'transaction',
            title: <FormattedMessage id="Transactions" />,
            type: 'item',
            url: '/transaction',
            icon: icons.IconCoin,
            breadcrumbs: false
        }
    ]
};

export default other;
