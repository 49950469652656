// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';

// types
import { DefaultRootStateProps } from 'types';
import { Pins } from 'types/merch';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['merch'] = {
    error: null,
    themes: [],
    pins: [],
    fetching: false
};

const slice = createSlice({
    name: 'merchStore',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
            state.fetching = false;
        },
        getListPending(state) {
            state.fetching = true;
        },
        getListSuccess(state) {
            state.fetching = false;
        },
        getPinsSuccess(state, { payload, type }) {
            state.pins = payload.data;
            state.fetching = false;
        },
        getThemesSuccess(state, { payload, type }) {
            state.themes = payload.data;
            state.fetching = false;
        },
        editThemeLocally(state, { payload }) {
            // const allThemes = [...state.themes];
            const foundAt = state.themes.findIndex((it) => it.id === payload.id);
            if (foundAt > -1) {
                state.themes[foundAt] = { ...state.themes[foundAt], ...payload };
            }
            state.fetching = false;
        },
        initialize() {
            return initialState;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const getPinsList = () => async () => {
    try {
        dispatch(slice.actions.getListPending());
        const { data } = await axios.get(`/award-pins`);
        dispatch(slice.actions.getPinsSuccess({ data }));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
};

export const getPin = (id: string) => async (): Promise<Pins | null> => {
    let result: Pins | null = null;
    try {
        dispatch(slice.actions.getListPending());
        const { data } = await axios.get(`/award-pins/${id}`);
        result = data;
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    } finally {
        dispatch(slice.actions.getListSuccess());
    }
    return result;
};

export const createPin =
    (
        payload: { name: string; description: string; price: number; expiryDurationInDays: number; logo: any },
        callBackSuccess?: () => void,
        callBackError?: (err: any) => void
    ) =>
    async () => {
        const { logo, ...rest } = payload;
        try {
            dispatch(slice.actions.getListPending());
            const { data } = await axios.post(`/award-pins`, rest);
            await axios.post(`/award-pins/${data.id}/logo`, logo, {
                headers: { accept: 'application/json', 'Content-Type': 'multipart/form-data' }
            });
            dispatch(slice.actions.getListSuccess());
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'pin/Award submitted - Submit Success',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            callBackSuccess?.();
        } catch (error) {
            callBackError?.(error);
            dispatch(slice.actions.hasError(error));
        }
    };

export const editPin =
    (
        payload: { id: number | string; name: string; description: string; price: number; expiryDurationInDays: number },
        callBackSuccess?: () => void,
        callBackError?: (err: any) => void
    ) =>
    async () => {
        const { id, ...rest } = payload;
        try {
            dispatch(slice.actions.getListPending());
            await axios.put(`/award-pins/${id}`, rest);
            dispatch(slice.actions.getListSuccess());
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'pin/Award updated',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            callBackSuccess?.();
        } catch (error) {
            callBackError?.(error);
            dispatch(slice.actions.hasError(error));
        }
    };

export const getThemeList = () => async () => {
    try {
        dispatch(slice.actions.getListPending());
        const { data } = await axios.get(`/themes`);
        dispatch(slice.actions.getThemesSuccess({ data }));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
};

export const createTheme =
    (
        payload: { name: string; price: number; details: { [key: string]: string } },
        callBack?: () => void,
        callBackError?: (err: any) => void
    ) =>
    async () => {
        try {
            dispatch(slice.actions.getListPending());
            await axios.post(`/themes`, payload);
            dispatch(slice.actions.getListSuccess());
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Theme submited - Submit Success',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            callBack?.();
        } catch (error) {
            callBackError?.(error);
            dispatch(slice.actions.hasError(error));
        }
    };

export const editTheme =
    (
        payload: { id: number; name: string; price: number; showOnStore: boolean; details: { [key: string]: string }; justHide?: boolean },
        callBack?: () => void,
        callBackError?: (err: any) => void
    ) =>
    async () => {
        const { id, justHide, ...restPayload } = payload;
        try {
            dispatch(slice.actions.getListPending());
            await axios.patch(`/themes/${id}`, restPayload);
            dispatch(slice.actions.editThemeLocally(payload));
            dispatch(
                openSnackbar({
                    open: true,
                    message: `${
                        justHide ? `Theme ${restPayload.showOnStore ? 'Show' : 'Hide'} Successfully` : 'Theme Updation - Updated Success'
                    }`,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            callBack?.();
        } catch (error) {
            callBackError?.(error);
            dispatch(slice.actions.hasError(error));
        }
    };

export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize());
};
