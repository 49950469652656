// third-party
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { settingsKeys } from 'utils';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';

// types
import { DefaultRootStateProps } from 'types';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['setting'] = {
    error: null,
    fetching: false,
    fetchingFor: '',
    settings: []
};

const slice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        // HAS ERROR
        initialize() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSetting.fulfilled, (state, { payload: settings }) => ({
                ...state,
                settings,
                fetching: false,
                error: null,
                fetchingFor: ''
            }))
            .addCase(setSetting.fulfilled, (state) => ({
                ...state,
                fetching: false,
                error: null,
                fetchingFor: ''
            }))

            .addMatcher(isAnyOf(getSetting.pending, setSetting.pending), (state, { payload: fetchingFor }) => ({
                ...state,
                fetching: true,
                fetchingFor
            }))
            .addMatcher(isAnyOf(setSetting.rejected, getSetting.rejected), (state, { payload: error }: any) => ({
                ...state,
                fetching: false,
                error,
                fetchingFor: ''
            }));
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const setSetting = createAsyncThunk(
    'setSetting',
    async (payload: { settingKey: string; settingValue: string; settingTitle: string }, { rejectWithValue }) => {
        let returner = null;
        const { settingTitle, ...rest } = payload;
        try {
            const { data } = await axios.post('/admin/settings', rest);
            returner = data;
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Setting ${settingTitle} - Submit Success`,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (error) {
            rejectWithValue(error);
        }
        return returner;
    }
);

export const getSetting = createAsyncThunk('getSetting', async (payload: {}, { rejectWithValue }) => {
    let returner = null;
    try {
        const { data } = await await axios.get(`/admin/settings`);
        const tempDate = new Date();
        if (data?.length >= 2) {
            returner = data;
        } else if (data?.length === 1) {
            const reviewWise = data.find((itm: any) => itm.settingKey === settingsKeys.reviewExp);
            const pinWise = data.find((itm: any) => itm.settingKey === settingsKeys.pinExp);
            if (reviewWise) {
                returner = [
                    reviewWise,
                    {
                        id: 2,
                        settingKey: settingsKeys.pinExp,
                        settingValue: '1',
                        createdAt: tempDate,
                        updatedAt: tempDate
                    }
                ];
            } else if (pinWise) {
                returner = [
                    {
                        id: 1,
                        settingKey: settingsKeys.reviewExp,
                        settingValue: '1',
                        createdAt: tempDate,
                        updatedAt: tempDate
                    },
                    pinWise
                ];
            }
        } else {
            returner = [
                {
                    id: 1,
                    settingKey: settingsKeys.reviewExp,
                    settingValue: '1',
                    createdAt: tempDate,
                    updatedAt: tempDate
                },
                {
                    id: 2,
                    settingKey: settingsKeys.pinExp,
                    settingValue: '1',
                    createdAt: tempDate,
                    updatedAt: tempDate
                }
            ];
        }
    } catch (error) {
        rejectWithValue(error);
    }
    return returner;
});

export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize());
};
