// third-party
import { combineReducers } from 'redux';

// project imports
import snackbar from './slices/snackbar';
import menu from './slices/menu';
import restaurant from './slices/restaurants';
import ssuser from './slices/ssuser';
import adminDashboard from './slices/adminPortal';
import merch from './slices/merch';
import setting from './slices/setting';
import transaction from './slices/transaction';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar,
    transaction,
    menu,
    restaurant,
    adminDashboard,
    setting,
    merch,
    ssuser
});

export default reducer;
