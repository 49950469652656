// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';
import { RestaurantItem } from 'types/restaurant';
// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['restaurant'] = {
    error: null,
    restaurants: [],
    fetching: false,
    count: 0,
    limit: 0,
    offset: 0
};

const slice = createSlice({
    name: 'restaurants',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        getRestaurantsPending(state) {
            state.fetching = true;
            state.error = null;
        },
        getRestaurantsFinally(state) {
            state.fetching = false;
        },
        // GET Restaurants
        getRestaurantsSuccess(state, { payload, type }) {
            state.restaurants = payload.data;
            state.count = payload.pagination.count;
            state.limit = payload.pagination.limit;
            state.offset = payload.pagination.offset;
            state.fetching = false;
            state.error = null;
        },
        initialize() {
            return initialState;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const getRestaurants =
    ({
        limit = 25,
        offset = 0,
        search = '',
        sortBy = 'id',
        sortOrder = 'ASC'
    }: {
        limit?: number;
        offset?: number;
        search?: string;
        sortBy?: string;
        sortOrder?: 'ASC' | 'DESC';
    }) =>
    async () => {
        try {
            dispatch(slice.actions.getRestaurantsPending());
            const { data } = await axios.get(
                `/restaurants?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ''}${sortBy ? `&sortBy=${sortBy}` : ''}${
                    sortOrder ? `&sortOrder=${sortOrder}` : ''
                }`
            );
            dispatch(slice.actions.getRestaurantsSuccess(data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

export const getRestaurantDetail = (id: number) => async (): Promise<RestaurantItem | null> => {
    let toReturn: RestaurantItem | null = null;
    try {
        dispatch(slice.actions.getRestaurantsPending());
        const { data } = await axios.get(`/restaurants/${id}`);
        toReturn = data;
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    } finally {
        dispatch(slice.actions.getRestaurantsFinally());
    }
    return toReturn;
};

export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize());
};
