import { lazy } from 'react';
// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthReset = Loadable(lazy(() => import('views/authentication/ResetPassword')));
const PageNotFound = Loadable(lazy(() => import('views/pageNotFound')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/reset-password',
            element: <AuthReset />
        },
        {
            path: '*',
            element: <PageNotFound />
        }
    ]
};

export default AuthenticationRoutes;
