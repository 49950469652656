// material-ui
import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

// assets
import defaultColor from 'assets/scss/_themes-vars.module.scss';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (navType: PaletteMode) =>
    createTheme({
        palette: {
            mode: navType,
            common: {
                black: defaultColor.darkPaper
            },
            primary: {
                light: navType === 'dark' ? defaultColor.darkPrimaryLight : defaultColor.primaryLight,
                main: navType === 'dark' ? defaultColor.darkPrimaryMain : defaultColor.primaryMain,
                dark: navType === 'dark' ? defaultColor.darkPrimaryDark : defaultColor.primaryDark,
                200: navType === 'dark' ? defaultColor.darkPrimary200 : defaultColor.primary200,
                800: navType === 'dark' ? defaultColor.darkPrimary800 : defaultColor.primary800
            },
            secondary: {
                light: navType === 'dark' ? defaultColor.darkSecondaryLight : defaultColor.secondaryLight,
                main: navType === 'dark' ? defaultColor.darkSecondaryMain : defaultColor.secondaryMain,
                dark: navType === 'dark' ? defaultColor.darkSecondaryDark : defaultColor.secondaryDark,
                200: navType === 'dark' ? defaultColor.darkSecondary200 : defaultColor.secondary200,
                800: navType === 'dark' ? defaultColor.darkSecondary800 : defaultColor.secondary800
            },
            error: {
                light: defaultColor.errorLight,
                main: defaultColor.errorMain,
                dark: defaultColor.errorDark
            },
            orange: {
                light: defaultColor.orangeLight,
                main: defaultColor.orangeMain,
                dark: defaultColor.orangeDark
            },
            warning: {
                light: defaultColor.warningLight,
                main: defaultColor.warningMain,
                dark: defaultColor.warningDark
            },
            success: {
                light: defaultColor.successLight,
                200: defaultColor.success200,
                main: defaultColor.successMain,
                dark: defaultColor.successDark
            },
            grey: {
                50: defaultColor.grey50,
                100: defaultColor.grey100,
                500: navType === 'dark' ? defaultColor.darkTextSecondary : defaultColor.grey500,
                600: navType === 'dark' ? defaultColor.darkTextTitle : defaultColor.grey900,
                700: navType === 'dark' ? defaultColor.darkTextPrimary : defaultColor.grey700,
                900: navType === 'dark' ? defaultColor.darkTextPrimary : defaultColor.grey900
            },
            dark: {
                light: defaultColor.darkTextPrimary,
                main: defaultColor.darkLevel1,
                dark: defaultColor.darkLevel2,
                800: defaultColor.darkBackground,
                900: defaultColor.darkPaper
            },
            text: {
                primary: navType === 'dark' ? defaultColor.darkTextPrimary : defaultColor.grey700,
                secondary: navType === 'dark' ? defaultColor.darkTextSecondary : defaultColor.grey500,
                dark: navType === 'dark' ? defaultColor.darkTextPrimary : defaultColor.grey900,
                hint: defaultColor.grey100
            },
            divider: navType === 'dark' ? defaultColor.darkTextPrimary : defaultColor.grey200,
            background: {
                paper: navType === 'dark' ? defaultColor.darkLevel2 : defaultColor.paper,
                default: navType === 'dark' ? defaultColor.darkPaper : defaultColor.paper
            }
        }
    });

export default Palette;
