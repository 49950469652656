import mainMenu from './mainMenu';
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [mainMenu]
};

export default menuItems;
