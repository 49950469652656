import { useEffect } from 'react';

// routing
import Routes from 'routes';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Locales from 'ui-component/Locales';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import { useAnalyticsEventTracker } from 'utils';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// ==============================|| APP ||============================== //

ReactGA.initialize(process.env.REACT_APP_ANALAYTICS || '', { testMode: false });
const pathSplitter = (pathname: string) => pathname.split('/')[1];

const App = () => {
    const { pathname } = useLocation();
    const gaEventTracker = useAnalyticsEventTracker('pageView');

    useEffect(() => {
        const spliced = pathSplitter(pathname);
        const title = `Strong Services - ${spliced ? spliced.charAt(0).toUpperCase() + spliced.slice(1, spliced.length) : 'Dashboard'}`;
        document.title = title;
        gaEventTracker(pathname, spliced);
        ReactGA.pageview(pathname, spliced as any);
    }, [pathname, gaEventTracker]);

    return (
        <ThemeCustomization>
            {/* RTL layout */}
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
