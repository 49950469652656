// third-party
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { openSnackbar } from 'store/slices/snackbar';
// types
import { DefaultRootStateProps } from 'types';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['ssuser'] = {
    error: null,
    users: [],
    fetching: false,
    count: 0,
    limit: 0,
    offset: 0,
    userType: ''
};

const slice = createSlice({
    name: 'ssuser',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
            state.fetching = false;
        },
        getUserPending(state) {
            state.fetching = true;
        },
        getUsersSuccess(state, { payload, type }) {
            state.users = payload.data;
            state.count = payload.pagination.count;
            state.limit = payload.pagination.limit;
            state.offset = payload.pagination.offset;
            state.userType = payload.userType;
            state.fetching = false;
        },
        updateCustomers(state, action) {
            state.users = [...state.users, ...action.payload];
        },
        initialize() {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendForgotRequest.fulfilled, (state, { payload, type }) => ({
                ...state,
                fetching: false,
                error: null
            }))

            .addMatcher(isAnyOf(sendForgotRequest.pending), (state) => ({
                ...state,
                fetching: true
            }))
            .addMatcher(isAnyOf(sendForgotRequest.rejected), (state, { payload }: any) => ({
                ...state,
                fetching: false,
                error: payload
            }));
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const getUsersList =
    ({
        userType,
        limit = 25,
        offset = 0,
        search = '',
        sortBy = 'id',
        sortOrder = 'ASC'
    }: {
        limit?: number;
        offset?: number;
        search?: string;
        sortBy?: string;
        userType?: 'server' | 'guest' | undefined;
        sortOrder?: 'ASC' | 'DESC';
    }) =>
    async () => {
        try {
            dispatch(slice.actions.getUserPending());
            const response = await axios.get(
                `/users?${userType ? `userType=${userType}` : ''}&limit=${limit}&offset=${offset}${search ? `&search=${search}` : ''}${
                    sortBy ? `&sortBy=${sortBy}` : ''
                }${sortOrder ? `&sortOrder=${sortOrder}` : ''}`
            );
            const { data } = response;
            dispatch(slice.actions.getUsersSuccess({ ...data, userType }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };

export const sendForgotRequest = createAsyncThunk('sendingForgotPassword', async (payload: { email: string }, { rejectWithValue }) => {
    let returner = null;
    try {
        const { data } = await axios.post('/users/forgot-password', payload);
        returner = data;
        dispatch(
            openSnackbar({
                open: true,
                message: 'Forgot Password - Sent',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    } catch (error) {
        rejectWithValue(error);
    }
    return returner;
});

export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize());
};
