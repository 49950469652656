// types
import { ConfigProps } from 'types/config';

export const JWT_API = {
    secret: '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
    timeout: '1 days'
};

export const FIREBASE_API = {
    apiKey: 'AIzaSyAl-J6Y6ifrpdPdCFeBlSc_QdtvxNXGjmE',
    authDomain: 'strong-services.firebaseapp.com',
    projectId: 'strong-services',
    storageBucket: 'strong-services.appspot.com',
    messagingSenderId: '456980085993',
    appId: '1:456980085993:web:ab6b34bff6048e6a454478',
    measurementId: 'G-E8MRLCTCQ3'
};

export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: 'us-east-1_AOfOTXLvD',
    appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/dashboard';

const config: ConfigProps = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light',
    presetColor: 'default',
    locale: 'en',
    rtlLayout: false,
    container: false
};

export default config;
